import React from 'react';
import Layout from '../components/layout';
import SiteText from '../components/sitetext';
import PortfolioSlider from '../components/portfolioslider';

const Portfolio = () => (
  <Layout title="Portfolio">
    <SiteText>
      <h1>Portfolio</h1>
      <div id="mainText">
        <p>
          {'Wir bieten eine verschiedenste Leistungen.'}
          {'Machen Sie sich ein Bild … schauen Sie ein Video!'}
        </p>
        <br />
        <center>
        <PortfolioSlider />
        </center>
      </div>
    </SiteText>
  </Layout>
);

export default Portfolio;
